<style scoped>
	.bg-h5{
		background: url('@/assets/images/h5.jpg') no-repeat center center;
		background-size: cover;
	}
	.bg-app{
		background: url('@/assets/images/lunbo3.jpg') no-repeat center center;
		background-size: cover;
	}
	.bg-chat{
		background: url('@/assets/images/chat.jpg') no-repeat center center;
		background-size: cover;
	}
	.carousel-container{
		height:12rem;
	}
	@media (min-width: 576px){
		.carousel-container{
			height:16rem;
		}
	}
	@media (min-width: 768px){
		.carousel-container{
			height:20rem;
		}
	}
	@media (min-width: 992px){
		.carousel-container{
			height:24rem;
		}
	}
	@media (min-width: 1200px){
		.carousel-container{
			height:28rem;
		}
	}
	@media (min-width: 1400px){
		.carousel-container{
			height:32rem;
		}
	}
</style>
<template>
	<div class="w-100 h-100 d-flex flex-column overflow-y-scroll scroll-none overflow-x-hidden">
		<div class="carousel-container" style="">
			<carousel></carousel>
		</div>
		<div class="flex-grow-1 container py-3 py-sm-4 h-0">
			<div class="h-100">
				<div class="row">
					<div v-for="(item,i) in cardList" :key="item" class="col-12 col-md-6 col-xl-4 mb-3 mb-sm-4">
						<div class="d-flex justify-content-end rounded bg-white py-2 px-3 p-sm-4 shadow" :class="{'bg-h5':i==0,'bg-app text-white':i==1,'bg-chat text-white':i==2}">
							
							<div class="d-flex flex-column justify-content-between text-nowrap">
								<div class="fw-bold mb-4 fs-4">{{item.title}}</div>
								<div v-if="i==0" class="d-flex flex-column">
									<!-- <button @click="goLink(item.url[0])" class="btn btn-link" type="button">
										<span>使用旧版</span>
									</button> -->
									<button @click="goLink(item.url[0])" class="btn btn-primary" type="button">
										<i class="bi bi-box-arrow-in-right me-1"></i>
										<span>立即体验</span>
									</button>
								</div>
								<button v-else-if="i==1" @click="goLink(item.url[0])" class="btn btn-primary" type="button">
									<i class="bi bi-cloud-arrow-down-fill me-1"></i>
									<span>立即下载</span>
								</button>
								<div v-else-if="i==2" class="d-flex ">
									<button @click="goLink(item.url[0])" class="btn btn-primary me-2" type="button">
										<i class="bi bi-cloud-arrow-down-fill me-1"></i>
										<span>立即下载</span>
									</button>
									<button @click="goLink(item.url[1])" class="btn btn-primary" type="button">
										<i class="bi bi-chat-dots-fill me-1"></i>
										<span>发起聊天</span>
									</button>
								</div>
							</div>
						</div>
						<div class="d-flex flex-column flex-grow-1 text-start me-3">
							<div class="fw-bold mb-1 mb-sm-2 lh-lg fs-5">简介</div>
							<div>{{item.text}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade show" :class="{'d-block':isShowModal}" id="myModal">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content"><div class="modal-header">
					<h4 class="modal-title">系统公告</h4>
					<button type="button" class="btn-close" data-bs-dismiss="modal" @click="isShowModal=false"></button>
				</div>
				<div class="modal-body text-start">
					{{notice}}
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="isShowModal=false">关闭</button>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import carousel from '@/components/carousel.vue'; 

export default {
	name: 'HomeView',
	components: {
		carousel
	},
	data(){
		return {
			isShowModal:true,
			notice:config.notice,
			cardList:config.cardList
		}
	},
	methods:{
		goLink(url){
			window.location.href=url;
		}
    }
};
</script>
