<style>
    .phone-view{width: 100%; background-color:white;display: flex;}
    .btn-item{
        background: linear-gradient(0deg,#64a7c4 0%, #85c1c0 50%, #628198 100%);
        color: white !important;
        border-color: #64a7c4;
    }
    .btn-downitem{
        background: linear-gradient(0deg,#51405d 0%, #a87ac9 50%, #51405d 100%);
        color: white !important;
        border-color: #64a7c4;
    }
    h3{color: #6dafc4;text-align: left; font-weight: bold;}
    p{text-align: left;}
</style>
<template>
    <div class="phone-view flex-grow-1 flex-column d-flex justify-content-start justify-content-sm-center position-relative">
        <div class="d-flex flex-column flex-sm-row">
            <div class="col-12 col-sm-6">
                <img class="mx-auto d-block img-fluid" src="@/assets/images/phonebg.png"/>
            </div>
            <div class="col-12 col-sm-6 position-xs-absolute position-sm-relative bottom-0 mb-3 mb-sm-0 px-3 pt-2">
                <h3>电话投注</h3>
                <p>{{phoneCon}}</p>
                <div class="d-flex pb-3">
                    <div class="col-2 col-xl-1">
                        <img class="mx-auto d-block" src="@/assets/images/bjllogo.png"/>
                    </div>
                    <div class="col-2 col-xl-1">
                        <img class="mx-auto d-block" src="@/assets/images/nnlogo.png"/>
                    </div>
                    <div class="col-2 col-xl-1">
                        <img class="mx-auto d-block" src="@/assets/images/lplogo.png"/>
                    </div>
                    <div class="col-2 col-xl-1">
                        <img class="mx-auto d-block" src="@/assets/images/tzlogo.png"/>
                        </div>
                        <div class="col-2 col-xl-1">
                        <img class="mx-auto d-block" src="@/assets/images/lhjlogo.png"/>
                        </div>
                    <div class="col-2 col-xl-1">
                        <img class="mx-auto d-block" src="@/assets/images/mjlogo.png"/>
                    </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between justify-content-sm-start text-nowrap">
                    <button v-for="item in phoneList" :key="item" type="button" class="btn btn-item me-sm-2 mb-2" @click="goLink(item.url)">{{item.text}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            phoneCon:config.phoneCon,
            phoneList:config.phoneList
        }
    },
    methods:{
      goLink(url){
        window.location.href=url;
      }
    }
}
</script>